
const NODE_ENV = process.env.NODE_ENV || 'dev';
const VERSION = '0.1.6'
const API_STAGE = process.env.REACT_APP_API_STAGE || 'Prod';
const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://gtasle68n5.execute-api.us-west-1.amazonaws.com';
const UNSUBSCRIBE_URI = process.env.REACT_APP_API_UNSUBSCRIBE_URI || 'unsubscribe-email';
let UNSUBSCRIBE_URL = '';
if (NODE_ENV==='production'){
    UNSUBSCRIBE_URL = `${BASE_URL}/${UNSUBSCRIBE_URI}`;
} else {
    UNSUBSCRIBE_URL = `${BASE_URL}/${API_STAGE}/${UNSUBSCRIBE_URI}`;
}


export {
    API_STAGE,
    NODE_ENV,
    UNSUBSCRIBE_URL,
    VERSION
}

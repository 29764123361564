import React, {useState} from 'react';
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {UNSUBSCRIBE_URL} from '../constants';

function Unsubscribe(props) {
    const [unsubscribed, setUnsubscribed] = useState(false)
    const [btnClicked, setBtnClicked] = useState(false)
    const [emailId, setEmailId] = useState("")

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const unsubscribeUser = async () => {
        setEmailId(params.get('emailId'))
        setBtnClicked(true)
        console.log(`unsubc located: ${params.get('unid')}`)
        console.log(`unsubc email: ${params.get('emailId')}`)
        const res = await sendUnsubData();
        console.log(emailId);
        console.log(res);
    }

    async function sendUnsubData() {
        const url = `${UNSUBSCRIBE_URL}/${params.get('unid')}`

        console.log('url', url)
        let fetchResponse
        try {
            fetchResponse = await fetch(url)
        } catch (err) {
            console.log("error fetching lead")
            console.log(err)
            console.log(err.status)
            if (err.hasOwnProperty('status')){
                console.log(err.status)
                setUnsubscribed(true)
            } else {
                setBtnClicked(false)
            }
        }
        console.log('fs', fetchResponse.status)

        if (fetchResponse.status !== 200){

            if (fetchResponse.status === 409){
                setUnsubscribed(true)
                toast.info('You are already removed from list')
            } else {
                setBtnClicked(false)
                toast.error('Error unsubscribing. Please try again')
            }
            return
        }
        console.log('fs - 2', fetchResponse.status)

        try {
            const data = await fetchResponse.json()
            console.dir(data)
            setUnsubscribed(true)
            toast.success("You have been removed from the mailing list")
        } catch (err) {
            console.log("error unsubscribe lead")
            console.log(err)
            console.log(err.status)
            if (err.hasOwnProperty('status')){
                console.log(err.status)
                setUnsubscribed(true)
            } else {
                setBtnClicked(false)
            }
        }

    }

    const styles = {
        backgroundColor: '#fff',
        minHeight: '200px'
    };

    const headerFooterStyles = {
        backgroundColor: '#F9B218',
    };

    const emailStyle = {
        color: '#F9B218',
    };

    return (
        <Card className="text-center">
            <Card.Header style={headerFooterStyles}>We hate to see you go</Card.Header>
            <Card.Body style={styles}>
                <Card.Title>We would like to tell you how sad we are to see you go</Card.Title>
                <Row className="pb-4">
                    {!unsubscribed && <span>We will unsubscribe <span style={emailStyle}> {params.get('emailId')} </span> you from the Workits mailing list </span>}
                </Row>
                <Row>
                    {unsubscribed && <h4 className="p-1">You have successfully been removed from the mailing list as <span style={emailStyle}> {params.get('emailId')} </span> </h4>}
                </Row>
                <Row className="pb-4">

                    <Card.Text>


                    </Card.Text>
                </Row>

                {!unsubscribed &&

                <Button disabled={btnClicked} onClick={unsubscribeUser} variant="primary">
                    {btnClicked && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                    Click Here to unsubscribe
                </Button>}
            </Card.Body>
            <Card.Footer className="text-muted" style={headerFooterStyles}>Please sign back up if you want to start receiving alerts again.</Card.Footer>

        </Card>
    );
}

export default Unsubscribe;
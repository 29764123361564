import React from 'react';
import Container from "react-bootstrap/Container";
import AppFooter from "./appFooter";
import Row from "react-bootstrap/Row";
import Unsubscribe from "./unsubscribe";
import Header from "./header";
import {ToastContainer} from "react-toastify";

const styles = {
    backgroundColor: '#fff',
    color: '#000',
};

function MainContainer(props) {

    return (
        <>
            <Header/>
            <ToastContainer position="top-right" autoClose={7500} pauseOnHover/>
            <Container fluid={"sm"} style={styles}>
                <Row className="p-5"></Row>
                <Unsubscribe></Unsubscribe>

                <Row>
                </Row>
                <AppFooter/>

            </Container>
        </>
    );
}

export default MainContainer;
import React from 'react';
import {VERSION} from "../constants";

function AppFooter(props) {
    return (
        <>
            <p className="float-start">Copyright &copy; 2023 WORKiTS LLC - All Rights Reserved</p>
            <p className="float-end">App Version: {VERSION}</p>
        </>
    );
}

export default AppFooter;

import {useEffect} from 'react';
import MainContainer from "./components/mainContainer";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  useEffect(() => {
    sendUnsubscribe();
  }, []);

  async function sendUnsubscribe() {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    console.log(`located: ${params.get('unid')}`)
    // await unsubscribe(params.get('unid'));
  }



  return (
      <MainContainer/>
  );
}

export default App;
